var navOpen = "closed";
var mobileEnd = 950;
var desktopStart = 951;
var navarea;


// Site credit colours
var nformColour1 = '#50cbcb';
var nformColour2 = '#50cbcb';

/*
$(function() {
    
    
    // Site credit button
    var nformTL = new TimelineMax();
        nformTL.to($(this).find(".nform-logo"), 0.15, {y:40, force3D:true, ease:Power2.easeInOut});
        nformTL.set($(this).find(".nform-logo"), {y:-40});
        nformTL.to($(this).find(".nform-logo"), 0.3, {y:0, opacity:1, force3D:true, ease:Power2.easeInOut});
        nformTL.to($(this).find(".nform-logo path"), 0.3, {fill:nformColour2, ease:Linear.easeNone}, "-=0.45");
        nformTL.to($(this).find(".nform-by"), 0.45, {color:nformColour1, force3D:true}, "-=0.45");
        nformTL.pause();   

    $(".nform-btn").hover(
        function() {
            nformTL.timeScale( 1 );
            nformTL.play();
        },
        function() {
            nformTL.timeScale( 0.8 );
            nformTL.reverse();
    });
 

	$(".showNav").click(
	function() {
		$(".mainNav").stop().animate({top: "0"}, { duration: 500, easing:'easeOutExpo' });
		return false;
	});

	$(".hideNav").click( 
	function() {
		$(".mainNav").stop().animate({top: "-260px"}, { duration: 450, easing:'easeOutExpo' });
		return false;
	});

	$(".navLink").click(
	function() {
		$(".mainNav").stop().animate({top: "-260px"}, { duration: 450, easing:'easeOutExpo' });
	});


	$("#siteCredit").hover(
	function() {  
		$("#northformText", this).stop().animate({"background-position-y": "-17"}, 100);
	},
	function() {
		$("#northformText", this).stop().animate({"background-position-y": "3"}, 300);
	});

	$('#siteCredit').trigger('mouseout');

});
*/



//////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALISE ////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
$(function() {
    

    // Nav ///////////////////////////////////////////////////////////////////
    $(".nav__burger").click(function() {
        navOpen = "open";
        
        TweenLite.to(".nav", 0, {autoAlpha:1, force3D:true});
        TweenLite.to('.nav-overlay-close', 0.3, {autoAlpha:1, force3D:true});
        return false;
        
    });
    
    
    // Responsive /////////////////////////////////////////////////////////
    var jRespon = jRespond([
        {
            label: 'portable',
            enter: 0,
            exit: mobileEnd
        },
        {
            label: 'desktop',
            enter: desktopStart,
            exit: 100000
        }
    ]);
    jRespon.addFunc([
        {
            breakpoint: 'portable',
            enter: function() {

                $(".nav").addClass( "navside--mobile" );
                $(".nav").removeClass( "navside--desktop" );
                $(".nav__main-btn, .nav__head-btns--signup").addClass( "navbtn--mobile" );
                $(".nav__main-btn, .nav__head-btns--signup").removeClass( "navbtn--desktop" );
                TweenLite.to(".navside--mobile", 0, {autoAlpha:0});
                navOpen = "closed";
                
                // Nav - mobile ////////////////////////////////////////////////////////////////            
                $.each($(".nav__main-btn"), function(i,e) {
                    $(e).off('mouseenter mouseleave');
                });
                TweenMax.set('.nav__main-btn', {clearProps:"all"});
                
                $(".navbtn--mobile").click(function() {
                    navOpen = "closed";
                    TweenLite.to(".nav", 0.3, {autoAlpha:0, force3D:true});
                });
                
                // Nav - small buttons /////////////////////////////////////////////////////////
                $.each($(".header-main__btn"), function(i,e) {
                    $(e).off('mouseenter mouseleave');
                });
                TweenMax.set('.header-main__btn', {clearProps:"all"});
                
                $(".nav__close").click(function() {
                    navOpen = "closed";
                    TweenLite.to(".nav", 0.3, {autoAlpha:0, force3D:true});
                    return false;
                });
                
                // Site credit //////////////////////////////////////////////////////////////////
                $.each($(".nform-btn"), function(i,e) {
                    $(e).off('mouseenter mouseleave');
                });
                TweenMax.set('.nform-btn', {clearProps:"all"});
                
            }
        }
    ]);
    jRespon.addFunc([
        {
            breakpoint: 'desktop',
            enter: function() {
                
                // Nav - main ////////////////////////////////////////////////////////////////                
                $(".nav").removeClass( "navside--mobile" );
                $(".nav").addClass( "navside--desktop" );
                $(".nav__main-btn, .nav__head-btns--signup").removeClass( "navbtn--mobile" );
                $(".nav__main-btn, .nav__head-btns--signup").addClass( "navbtn--desktop" );
                TweenLite.to(".navside--desktop", 0, {autoAlpha:1});
                navOpen = "closed";

                
                $.each($(".navbtn--desktop"), function(i,e) {
                    $(e).hover(
                        function() {
                            
                        },
                        function() {
                            
                        });
                });
                $(".navbtn--desktop").click(function() {
                    TweenLite.to(".nav", 0.01, {autoAlpha:1});
                });

                
                
            }
        }
    ]);
    
});







/* =============================================================================
	PAGE - HOME
========================================================================== */	
function homeJS() {
	
	$(".royalSlider").royalSlider({
		autoScaleSlider: true,
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		slidesSpacing: 0,
		imageScalePadding: 0,
    	autoScaleSliderWidth: 960,     
	    autoScaleSliderHeight: 540,
		autoPlay: {
    		enabled: true,
			delay: 7000
    	},
	    video: {
	      autoHideArrows:true,
    	  autoHideControlNav:true
	    },  
	});  
	
};

/* =============================================================================
	PAGE - ABOUT ME
========================================================================== */	
function aboutmeJS() {
	
	$(".quoteSlider").royalSlider({
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		autoPlay: {
    		enabled: true,
			delay: 5000
    	},
	});  
	
};

/* =============================================================================
	PAGE - CONSULTATION
========================================================================== */	
function consultationJS() {
	
	$(".quoteSlider").royalSlider({
		autoHeight: true,
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		autoPlay: {
    		enabled: true,
			delay: 5000
    	},
	});  
	
};

/* =============================================================================
	PAGE - EMOTIONAL
========================================================================== */	
function emotionalJS() {
	
	$(".quoteSlider").royalSlider({
		autoHeight: true,
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		autoPlay: {
    		enabled: true,
			delay: 5000
    	},
	});  
	
};

/* =============================================================================
	PAGE - PERSONAL
========================================================================== */	
function personalJS() {
	
	$(".quoteSlider").royalSlider({
		autoHeight: true,
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		autoPlay: {
    		enabled: true,
			delay: 5000
    	},
	});  
	
};

/* =============================================================================
	PAGE - CONTACT
========================================================================== */	
function contactJS() {


};


/* =============================================================================
	SIDEBAR
========================================================================== */	
function sidbarJS() {
	
	$("#royalSliderSide").royalSlider({
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		slidesSpacing: 0,
		imageScalePadding: 0,
		autoPlay: {
    		enabled: true,
			delay: 3000
    	},
	});  
	
};

/* =============================================================================
	BLOG VIEW
========================================================================== */	
function blogViewJS() {
	
	$("#royalSliderBlog").royalSlider({
		autoScaleSlider: true,
    	autoScaleSliderWidth: 960,     
	    autoScaleSliderHeight: 540,
		loop: true,
		arrowsNav: false,
		controlNavigation: 'none',
		slidesSpacing: 0,
		imageScalePadding: 0,
		autoPlay: {
    		enabled: false,
			delay: 3000
    	},
	});  
	
};


/* =============================================================================
	FEATURE VIEW
========================================================================== */	
function featureJS() {
	
	$(".body-content__content-block").fitVids();
	
};



